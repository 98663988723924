<template>
    <div class="Review">
        <div class="ReviewItem">
            <div class="ReviewText">
                Отличный опыт. Намного лучше, чем ожидалось. Сергей большой профессионал, он привлек мое внимание с самого начала. Совершенно рекомендую!!
            </div>
            <Spacer16></Spacer16>
            <div class="ReviewAuthor">
                <img src="https://s3-alpha-sig.figma.com/img/418a/f7ad/deab4a457eff43838555f24a193d678f?Expires=1722816000&amp;Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&amp;Signature=LHlQJv0zTp865TlNy55yl9iyj2ezsLJr9grTPoszYS2fzVrMvG6aZbVRuukGLqlH-UAS1PsvQVbYmfp2Krr-KIz7oiqrvzCXs83CsuzUqjTbkwb6C5CNU0CfCbv-BflTMyW6Osf47fzJLws1ottxqfY6Q-Ty8nVOb55wc~9-02vBhquecWEf4WYYGzIoOh1rnncCb42EZwX-fXDceVjNTttTeqpeL6Hzkxf2eCY-NFivJoqohZK6tyJFBnoZ7pngMI8SSY6ftL4XLee~WCjUhdA~NQytkTwjnPPqnwxBv99UOE7KXID3EOfjGgUpeLiDV8F12ffb2htKxNd--RlWXQ__" alt="Mentor Image" class="ReviewPFP" />
                <div class="ReviewName">
                    Антон Гандон
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
//   import axios from 'axios';
    // import ReviewItem from './ReviewItem.vue';
    // import ReviewList from './ReviewList.vue';
    import Spacer16 from "../Spacers/SpacerModule16.vue"
    export default {
    name: 'ReviewItem',
    components: {
    //     ReviewItem,
        Spacer16,
    //     ReviewList,
    },
    // props: {
    //   review_text: {
    //     type: String,
    //     required: true
    //   },
    //   review_author: {
    //     type: String,
    //     required: true
    //   },
    //   review_event: {
    //     type: Number,
    //     required: true
    //   },
    //   event_imageUrl: {
    //     type: String,
    //     required: true
    //   },
    //   event_ageRestriction: {
    //     type: String,
    //     required: true
    //   },
    //   event_availableSpots: {
    //     type: Number,
    //     required: true
    //   },
    //   event_totalSpots: {
    //     type: Number,
    //     required: true
    //   },
    //   event_category: {
    //     type: String,
    //     required: true
    //   }
    // }
    }

</script>

<style scoped>
    html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
    }
    .ReviewItem {
        display: flex;
        flex-direction: column;
        /* overflow: hidden; */
        background-color: #F6F6F6;
        /* width: 100%; */
        height: 12vh;
        border-radius: 0.5208vw;
        padding: 1.5vw;
    }
    .ReviewPFP {
        height:1.5vw;
        width:1.5vw;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
        align-self: center;
        margin-right:1vw;
    }
    .ReviewAuthor {
        display: flex;
        flex-direction: row;
    }
    .ReviewName {
        color: #454152;
        font-size: 1vw;
        font-weight: 500;
    }
    .ReviewText {
        color: #454152;
        font-size: 1vw;
        font-weight: 300;
    }
</style>
  