<template>
<div class="event-card">
    <img :src="event_imageUrl" alt="Event Image" class="event-image" />
    <div class="event-info">
        <div class="event-details">
            <div class="event-time-n-cost">
                <span class="event-date">{{ formated(event_date)  }}</span>
                <span v-if="event_price != 0" class="event-price" id="overlay">{{ event_price }} ₽</span>
                <span v-if="event_availiableSpots == 0" class="sold" >sold </span>
                <span v-if="event_ageRestriction >= 18" class="ageRest" >18+</span>
            </div>

            <div class="some-class">
                <div v-if="event_price == 0" class="event-price-free">free</div>
                <img v-if="event_category == 'Health'" src="../assets/images/category/health_icon.svg" alt="Логотип" class="category-image" id="overlay">
                <img v-else-if="event_category == 'Career'" src="../assets/images/category/career_icon.svg" alt="Логотип" class="category-image" id="overlay">
                <img v-else-if="event_category == 'Beauty'" src="../assets/images/category/beauty_icon.svg" alt="Логотип" class="category-image" id="overlay">
                <img v-else-if="event_category == 'Education'" src="../assets/images/category/edu_icon.svg" alt="Логотип" class="category-image" id="overlay">
                <img v-else-if="event_category == 'Hobby'" src="../assets/images/category/hobby_icon.svg" alt="Логотип" class="category-image" id="overlay">
                <img v-else-if="event_category == 'Light'" src="../assets/images/category/light_icon.svg" alt="Логотип" class="category-image" id="overlay">
            </div>
        </div>
        <div class="event-title">
            {{event_title}}
        </div>
    </div>
</div>
</template>
  
<script>
//   import axios from 'axios';
// import { useDateFormat, useNow } from '@vueuse/core'
import moment from 'moment';
export default {
    name: 'EventCard',
    props: {
        id: {
            type: String,
            required: true
        },
        event_title: {
            type: String,
            required: true
        },
        event_date: {
            type: Date,
            required: true
        },
        event_price: {
            type: Number,
            required: true
        },
        event_imageUrl: {
            type: String,
            required: true
        },
        event_ageRestriction: {
            type: Number,
            required: true
        },
        event_availiableSpots: {
            type: Number,
            required: true
        },
        event_totalSpots: {
            type: Number,
            required: true
        },    
        event_category: {
            type: String,
            required: true
        }
    },
    methods: {
        formatDate(d) {
            return d.toLocaleString('ru-RU').replace(',', '').slice(0, -3);
        },
        formated(value) {
            return moment(String(value.toLocaleString('ru-RU'))).format('DD MMMM hh:mm');
        },
    },
}
</script>
  
<style scoped>
    html, body {
        margin: 0;
        padding: 0;
        width: 100vw;
    }
    .event-card {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: #fff;
        width: 100%;
        border-radius: 10px;
    }

    .event-card:hover {
        background-color: #f3f4f5;
        color: white;
        cursor: pointer;
    }
    
    .event-image {
        height: 219px;
        object-fit: cover;
        border-radius: 10px;
    }

    .category-image {
        width: 28px;
        border-radius: 14px;
    }
    
    .event-info {
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .event-time-n-cost {
        display: flex;
        justify-content: left;
        align-items: center;
    }
    .event-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    #overlay {
        background-color: white;
        position:relative;
        z-index: 1;
        margin-left: -4px;
    }

    .event-date,
    .event-price {
        font-size: 13px;
        color: #555;
        padding: 4px 8px 4px 8px;
        border-radius: 100px;
        border: solid 1px #DAD9DC;
        z-index: 2;
    }

    .event-price-free {
        font-size: 13px;
        color: white;
        padding: 4px 8px 4px 8px;
        border-radius: 100px;
        border: solid 1px #DAD9DC;
        background: #7552E9;
    }
    
    .event-title {
        font-size: 20px;
        font-weight: lighter;
        /* font-weight: 400; */
        color: #454152;
    }
    
    .event-meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #777;
    }

    .some-class {
        display: flex;
        position:relative;
        justify-content: flex-end;
        align-items: right;
    }

    .sold {
        font-size: 13px;
        color: white;
        padding: 4px 8px 4px 8px;
        border-radius: 100px;
        border: solid 1px #DAD9DC;
        background: #454152;
        position:relative;
        z-index: 3;
        margin-left: -4px;
    }

    .ageRest {
        font-size: 13px;
        color: white;
        padding: 4px 8px 4px 8px;
        border-radius: 100px;
        border: solid 1px #DAD9DC;
        background: #454152;
        position:relative;
        z-index: 4;
        margin-left: -4px;
    }

    .router {
        text-decoration: none;
        padding: 0;
        margin: 0;
    }

</style>
    