import { createRouter, createWebHistory } from 'vue-router'

import AllEvents from '../views/AllEvents.vue'
import MentorPage from '../views/MentorPage'
import HomePage from '../views/HomePage.vue'
import EventInfo from '../views/EventInfo.vue'
import RegisterPage from '../views/RegisterPage.vue';
import LoginPage from '../views/LoginPage.vue';
import TestPage from '../views/TestPage.vue';
// import HomeView from './HomeView.vue'
// import AboutView from './AboutView.vue'

const routes = [
  { path: '/Home', component: HomePage },
//   { path: '/about', component: AboutView },
  { path: '/AllEvents', component: AllEvents },
  { path: '/Mentor', component: MentorPage},
  { path: '/', component: HomePage},
  { path: '/event_info/:id', name: 'EventInfo', component: EventInfo },
  { path: '/mentor_info/:id', name: 'MentorInfo', component: MentorPage },
  { path: '/register', component: RegisterPage },
  { path: '/login', component: LoginPage },
  { path: '/TestPage', component: TestPage },
  { path: '/dashboard', component: () => import('../views/DashboardPage.vue'), meta: { requiresAuth: true } },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(/* to, from, savedPosition */) {

        // if (savedPosition) {
          // If there's a saved scroll position (e.g., after using the browser's back button), use that.
        //   return savedPosition;
        // } else {
          // Otherwise, scroll to the top of the page.
          return { top: 0 };
        // }
      }
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('accessToken');
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next('/login');  // Если не авторизован, перенаправляем на страницу входа
  } else {
    next();  // В противном случае, разрешаем переход
  }
});

// const router = new VueRouter({
//     mode: history,
//     base: process.env.BASE_URL, 
//     routes,
// })

export default router



