<template>
    <div id="eventInfo">
    <back-button/>
    <Spacer-32/>
    <div class="EventHero">
        <div class="EventDescription">
            <div class="EventInfoAbout">
                <div class="CategoryItem">
                    <div class="CategoryItemText">
                        здоровье
                    </div>
                    <div>
                        Поделиться
                    </div>
                </div>
                <div class="lll">
                    <div class="EventAbout">
                        <!-- {{this.qurrentevent.event_}} -->
                        Разберемся, как солнечные лучи действуют на кожу и что происходит, 
                        когда мы загораем. Выясним, как наслаждаться солнцем и не обжечься, 
                        поделимся советами, как ухаживать за кожей, чтобы она была здоровой 
                        и счастливой. 
                    </div>
                    <div class="EventName">
                        <!-- Загораем на солнце правильно. Без ожогов и вреда для кожи -->
                        {{this.qurrentevent.event_title}}
                    </div>
                </div>
            </div>
            <div class="EventRegistrationInfo">
                <div class="EventRegistrationInfoItem">
                    <div class="RegistrationInfoText">
                        <!-- 1500 руб. -->
                        {{this.qurrentevent.event_price}} руб.
                    </div>
                    <div class="RegistrationButton">
                        Зарегистрироваться
                    </div>
                </div>
                <div class="EventRegistrationInfoItem">
                    <div class="EventRegistrationDateInfo">
                        <div class="RegistrationInfoText">
                            <!-- 12 июня среда 18:00 -->
                            {{this.qurrentevent.event_date}}

                        </div>
                        <div class="RegistrationSlotsInfo">
                            {{this.qurrentevent.event_availableSpots}} мест
                        </div>
                    </div>
                    <div class="AtherDatesButton">
                        Посмотреть другие даты
                    </div>
                </div>
            </div>
        </div>
        <img src="https://s3-alpha-sig.figma.com/img/d059/5dc7/5775900c6f74bb70a4189433330a57d7?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QYFo8IpxJiSk2wlG-EtbvKFtYhh8liRFaIx-meo7zzicaSBQh0qgf1BFZWFl82IvmvJPztS0bW35hGdk4bqQISu~Fjfm7eR3QcJ863JrmBuTGZN1yeMw-yckX4Ap5GpOx7gSO4NXZE~oS6Wzbz30nZ9g2FKSBi7o2GHv41d25IOrdZEvL2UsaySnel~MJ3YhDhGSt7F3F-JUMznJXgEyhD5-VkPH8kZp7IAcJVYBpMn2Lig7aBIr5nxotVgb6cd0vxEMj0ZOs7d2BuhbyVHFHpORIxABFqEWptdhWmpu3xpL9-58HxbITLhmMaoqOaWnzfpCTprv6uC4RjfZxJVDDg__" alt="EventImage" class="EventImage"/>
        <!-- <img src="https://papik.pro/izobr/uploads/posts/2023-03/1679551780_papik-pro-p-ochen-krasivii-peizazh-44.jpg" alt="EventImage" class="EventImage"/> -->
    </div>
    <Spacer-48/>
    <div class="chapter">
        О мероприятии
    </div>
    <Spacer-24/>
    
    <div class="AboutEvent">
        <div class="AboutMentor">
            <img src="https://s3-alpha-sig.figma.com/img/418a/f7ad/deab4a457eff43838555f24a193d678f?Expires=1722816000&amp;Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&amp;Signature=LHlQJv0zTp865TlNy55yl9iyj2ezsLJr9grTPoszYS2fzVrMvG6aZbVRuukGLqlH-UAS1PsvQVbYmfp2Krr-KIz7oiqrvzCXs83CsuzUqjTbkwb6C5CNU0CfCbv-BflTMyW6Osf47fzJLws1ottxqfY6Q-Ty8nVOb55wc~9-02vBhquecWEf4WYYGzIoOh1rnncCb42EZwX-fXDceVjNTttTeqpeL6Hzkxf2eCY-NFivJoqohZK6tyJFBnoZ7pngMI8SSY6ftL4XLee~WCjUhdA~NQytkTwjnPPqnwxBv99UOE7KXID3EOfjGgUpeLiDV8F12ffb2htKxNd--RlWXQ__" class="MentorImage"/>
            <div class="MentorDescription">
                <div class="MentorName">
                    Сергей Мейерхольд
                </div>
                <Spacer-8/>
                <div class="MentorReview">
                    <!-- <div class="star"></div> -->
                    <div class="MentorReviewScore">
                        4.6
                    </div>
                    <div class="MentorReviewCount">
                        16 отзывов
                    </div>
                </div>
                <Spacer-16/>
                <div class="MainText">
                    Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. 
                    Специализируюсь на еде, тренер по бариста для национальных соревнований по кофе. 
                    Владелец бренда Chtozapizdec - компании, посвященной эмоциям и ощущениям
                </div>
                <Spacer-16/>
                <div class="MentorButtons"> 
                    <link-to-mentor>
                        <div class="MentorButton">Подробнее</div>
                    </link-to-mentor>
                    <div class="MentorButton">Оставить отзыв</div>
                </div>
            </div>
        </div>
        <div class="EventSpecification">
            <div class="EventAditionalInfo">
                <div class="EventRating">
                    <div class="InfoBlock">
                        <div class="AboutEventText">
                            Рейтинг
                        </div>
                        <spacer-16/>
                        <div class="MainText">
                            4.8
                        </div>
                    </div>
                </div>
                <div class="EventAgeRestriction">
                    <div class="InfoBlock">
                        <div class="AboutEventText">
                            Возраст
                        </div>
                        <spacer-16/>
                        <div class="MainText">
                            от 14 лет
                        </div>
                    </div>
                </div>
                <div class="EventMembers">
                    <div class="InfoBlock">
                        <div class="AboutEventText">
                            Участники
                        </div>
                        <spacer-16/>
                        <div class="MainText">
                            до 10 гостей
                        </div>
                    </div>
                </div>
            </div>

            <div class="EventRules">
                <div class="InfoBlock">
                    <div class="AboutEventText">
                        Правила отмены
                    </div>
                    <spacer-16/>
                    <div class="MainText">
                        Полный возврат доступен при отмене не позднее чем за 7 дней 
                        до начала мастер-класса или в течение 24 часов с момента 
                        бронирования
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Spacer-32/>
    <ReviewBlock/>
    <Spacer-32/>
    <div class="chapter">
        Другие мероприятия
    </div>
    <Spacer-32/>
    <div class="card-list">
        <EventCardVue v-for="event in events" :key="event.id"
            :event_title="event.event_title"
            :event_date="event.event_date"
            :event_price="event.event_price"
            :event_imageUrl="event.event_imageUrl"
            :event_ageRestriction="event.event_ageRestriction"
            :event_availableSpots="event.event_availableSpots"
            :event_totalSpots="event.event_totalSpots"
            :event_category="event.event_category"
        >
        </EventCardVue>
    </div>
    <Spacer-32/>
    <Spacer-8/>
    <div>{{qurrentevent.event_title}}</div>
</div>

</template>

<script>
import BackButton from '../components/Navigations/BackButton.vue';
import Spacer32 from '../components/Spacers/SpacerModule32.vue';
import Spacer48 from '../components/Spacers/SpacerModule48.vue';
import Spacer24 from '../components/Spacers/SpacerModule24.vue';
import Spacer8 from '../components/Spacers/SpacerModule8.vue';
import Spacer16 from '../components/Spacers/SpacerModule16.vue';
import ReviewBlock from '../components/Review/ReviewBlock.vue';
import EventCardVue from "../components/EventCard.vue";
import axios from 'axios';
import LinkToMentor from '../components/Links/LinkToMentor.vue';
export default {
    name: "EventInfo",
    components: {
        Spacer32,
        BackButton,
        Spacer48,
        Spacer24,
        Spacer16,
        Spacer8,
        ReviewBlock,
        EventCardVue,
        LinkToMentor,
    },
    props: {
        event_id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            events: [],
            qurrentevent: null,
            id: null,
        };
    },
    methods: {
        async fetchData() {
            try {
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvents/');
                this.events = response.data;
                console.log(response.data)
                console.log(this.events)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchEvent() {
            try {
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvent/?id='+this.$route.params.id);
                this.qurrentevent = response.data;
                console.log(response.data)
                console.log(this.qurrentevent)
            } catch (error) {
                console.error('Error fetching data:', error);
            } 
        }
    },
    mounted() {
        // this.id 
        // console.log(this.id)
        this.fetchEvent()
        this.fetchData()
    },

    // ________________________________________________
    // export default {
    // data() {
    //     return {
    //     userIds: [],
    //     };
    // },
    // methods: {
    //     async addUserId() {
    //     const userId = await this.getUserId(); // Assume this function fetches the logged user's ID
    //     const response = await fetch('/api/userlist/add_user_id/', {
    //         method: 'POST',
    //         headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.getItem('token')}`, // If you're using JWT
    //         },
    //         body: JSON.stringify({ user_id: userId }),
    //     });
    //     const data = await response.json();
    //     if (response.ok) {
    //         this.userIds = data.user_ids; // Update the local user IDs
    //     } else {
    //         console.error(data);
    //     }
    //     },
    //     async getUserId() {
    //     // Implement the logic to retrieve the logged-in user's ID
    //     // For example, return a hardcoded user ID or fetch it from your store/state
    //     return 1; // Example user ID
    //     },
    // },
    // };
    // ______________
}
</script>

<style scoped> 
#eventInfo {
    padding: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.AtherDatesButton {
    padding: 12px 24px 12px 24px;
    border-radius: 24px;
    background-color: #ffffff;
    color: #454152;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.RegistrationButton {
    padding: 12px 24px 12px 24px;
    border-radius: 24px;
    background-color: #454152;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.RegistrationButton:hover {
    background-color: #6553ca;
    color: white;
    cursor: pointer;
}

.RegistrationSlotsInfo {
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;
    color: #454152;
    padding: 4px 8px 4px 8px;
    border-radius: 12px;
    border: 1px solid #454152;
}

.RegistrationInfoText {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #454152;
}

.EventRegistrationDateInfo {
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.EventRegistrationInfoItem {
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.CategoryItem {
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CategoryItemText {
    background: white;
    padding: 4px 8px 4px 8px;
    min-width: 10px;
    max-width: 100px;
    max-height: 20px;
    height: fit-content;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #DAD9DC;
    font-size: 13px;
    font-weight: 500;
    color: #454152;
}

.lll {
    text-align: bottom;
    padding: 24px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
}

.EventAbout {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: bottom;
    color: #454152;
}

.EventName {
    font-size: 56px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: bottom;
    color: #454152;
}

.InfoBlock {
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.AboutEventText {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #454152;
}

.MentorButtons {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.MentorButton {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    padding: 6px 24px 8px 24px;
    background: white;
    border-radius: 20px;
    border: 1px solid #DAD9DC;
}

.MentorButton:hover {
    background: #454152;
    color: white;
    cursor: pointer;
}

.MainText {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #454152;
}

.MentorReviewCount {
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    /*line-height: 16px;*/
    padding: 4px 8px;
    border: 1px solid #DAD9DC;
    border-radius: 20px;
    text-align: left;
}

.MentorReview {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.MentorReviewScore {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.MentorName {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #454152;
}

.star {
    position: absolute;
    left: 10.73%;
    right: 10.73%;
    top: 10.96%;
    bottom: 14.16%;

    border: 1.5px solid #454152;
    border-radius: 0.5px;

}

.chapter {
    color: #454152;
    font-family: 'PlatformaFont';
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;
}

.EventHero {
    display: grid;
    gap: 8px;
    width: 100%;
    grid-template-columns: 7fr 3fr;
}

.EventDescription {
    display: grid;
    gap: 8px;
    grid-template-rows: 3fr 1fr;

}

.EventInfoAbout {
    display: grid;
    background-color: #F6F6F6;
    border-radius: 12px;
}

.EventRegistrationInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    background-color: #F6F6F6;
    border-radius: 12px;
    padding: 24px;
}

.EventImage {
    display: grid;
    border-radius: 12px;
    width: calc((100vw - 64px) * 3 / 10 - 8px);
    height: calc(calc((100vw - 64px) * 3 / 10 - 8px) * 4 / 3);
    object-fit: cover;
}

.AboutEvent {
    display: grid;
    grid-template-columns: 3fr 3fr;
    gap: 8px;
    height: 25%;
}

.AboutMentor {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 24px;
    background: #F6F6F6;
    border-radius: 12px;
}

.MentorDescription {
    display: flex;
    flex-direction: column;
}

.MentorImage {
    border-radius: 8px;
    height: 100%;
    width: 20%;
    object-fit: cover;
}

.EventSpecification {
    display: grid;
    grid-template-rows: 0.8fr 1.2fr;
    gap: 8px;
}

.EventAditionalInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
}

.EventRules {
    background-color: #F6F6F6;
    border-radius: 12px;
}

.EventRating {
    background-color: #F6F6F6;
    border-radius: 12px;
}

.EventAgeRestriction {
    background-color: #F6F6F6;
    border-radius: 12px;
}

.EventMembers {
    background-color: #F6F6F6;
    border-radius: 12px;
}

.card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px 8px;
    width: 100%;
}
</style>
