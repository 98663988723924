<template>
    <div id="Mentor" style="padding: 0">
        <BackButtonVue></BackButtonVue>
        <Spacer32/>
        <div class="MentorHero">
            <img src="https://s3-alpha-sig.figma.com/img/418a/f7ad/deab4a457eff43838555f24a193d678f?Expires=1722816000&amp;Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&amp;Signature=LHlQJv0zTp865TlNy55yl9iyj2ezsLJr9grTPoszYS2fzVrMvG6aZbVRuukGLqlH-UAS1PsvQVbYmfp2Krr-KIz7oiqrvzCXs83CsuzUqjTbkwb6C5CNU0CfCbv-BflTMyW6Osf47fzJLws1ottxqfY6Q-Ty8nVOb55wc~9-02vBhquecWEf4WYYGzIoOh1rnncCb42EZwX-fXDceVjNTttTeqpeL6Hzkxf2eCY-NFivJoqohZK6tyJFBnoZ7pngMI8SSY6ftL4XLee~WCjUhdA~NQytkTwjnPPqnwxBv99UOE7KXID3EOfjGgUpeLiDV8F12ffb2htKxNd--RlWXQ__" alt="Mentor Image" class="MentorImage" />
            <div class="MentorDescription">
                <div class="MentorIntroAbout">
                    <div class="BigInfoText">Сергей Мейерхольд</div>
                    <br>
                    <div class="MentorIntroWords">
                        Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. Специализируюсь на еде, тренер по бариста для национальных соревнований по кофе. Владелец бренда Chtozapizdec - компании, посвященной эмоциям и ощущениям
                    </div>
                </div>
                <div class="MentorSkills">
                    <div class="BigInfoText">Могу научить:</div>
                    <div class="SmallInfoText">
                        <ul>
                            <li>
                                Разбираться в сортах и обжарках зерен
                            </li>
                            <li>
                                Готовить альтернативу на уровне топовых кофеен дома
                            </li>
                            <li>
                                Настраивать помол и машину за считанные секунды
                            </li>
                            <li>
                                Делать латте арт с закрытыми глазами.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="MentorExperience">
                    <div class="BigInfoText">10 лет</div>
                    <div class="SmallInfoText">опыта работы</div>
                </div>
                <div class="MentorRating">
                    <div class="BigInfoText">4.96</div>
                    <div class="SmallInfoText">рейтинг ментора</div>
                </div>
                <div class="MentorEducation">
                    <div class="BigInfoText">Яндекс Практикум</div>
                    <div class="SmallInfoText">образование</div>
                </div>
            </div>
        </div>
        <ReviewBlock></ReviewBlock>
        <!-- <div class="MentorHero">
            <img src="https://s3-alpha-sig.figma.com/img/418a/f7ad/deab4a457eff43838555f24a193d678f?Expires=1722816000&amp;Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&amp;Signature=LHlQJv0zTp865TlNy55yl9iyj2ezsLJr9grTPoszYS2fzVrMvG6aZbVRuukGLqlH-UAS1PsvQVbYmfp2Krr-KIz7oiqrvzCXs83CsuzUqjTbkwb6C5CNU0CfCbv-BflTMyW6Osf47fzJLws1ottxqfY6Q-Ty8nVOb55wc~9-02vBhquecWEf4WYYGzIoOh1rnncCb42EZwX-fXDceVjNTttTeqpeL6Hzkxf2eCY-NFivJoqohZK6tyJFBnoZ7pngMI8SSY6ftL4XLee~WCjUhdA~NQytkTwjnPPqnwxBv99UOE7KXID3EOfjGgUpeLiDV8F12ffb2htKxNd--RlWXQ__" alt="Mentor Image" class="MentorImage" />
            <div class="MentorDescription">
                <div class="MentorIntroAbout">    
                </div>
                <div class="MentorContainerDescription">
                    <div class="MentorSkills">
                    </div>
                    <div class="MentorAdditionalInfo">
                        <div class="MentorBlock3">
                            <div class="MentorExperience">
                            </div>
                            <div class="MentorRating">
                            </div>
                        </div>
                        <div class="MentorEducation">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <Spacer32/>
        <div class="ActualMero">
            <div class="Title"> Актуальные мероприятия</div>
            <Spacer32/>
            <div class="card-list">
                <div v-for="event of events" :key="event.id">
                <link-to-event :id="event.id">
                    <EventCardVue 
                        :event_title="event.event_title"
                        :event_date="event.event_date"
                        :event_price="event.event_price"
                        :event_imageUrl="event.event_imageUrl"
                        :event_ageRestriction="event.event_ageRestriction"
                        :event_availableSpots="event.event_availableSpots"
                        :event_totalSpots="event.event_totalSpots"
                        :event_category="event.event_category"
                        :id="event.id"
                    >
                    </EventCardVue>
                </link-to-event>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
    import EventCardVue from "../components/EventCard.vue";
    import BackButtonVue from '../components/Navigations/BackButton.vue';
    import ReviewBlock from "../components/Review/ReviewBlock.vue";
    import axios from 'axios'
    import Spacer32 from "../components/Spacers/SpacerModule32.vue"
import LinkToEvent from '../components/Links/LinkToEvent.vue';

    export default {
    name: 'MentorPage',
    components: {
        EventCardVue,
        Spacer32,
        BackButtonVue,
        ReviewBlock,
        LinkToEvent,
    },
    data() {
        return {
        user_name: '',
        user_surname: '',
        formats: [ 'Формат 1', 'Формат 2', 'Формат 3' ],
        categories: [
            'Все категории',
            'Здоровье',
            'Карьера',
            'Образование',
            'Красота',
            'Хобби',
            'Просвещение'
        ],
        selectedCategory: 'Все категории',
        events: []
        };
    },
    methods: {
        handleFormatSelect(option) {
        console.log('Selected format:', option);
        },
        async fetchData() {
            try {
                const response = await axios.get('http://platforma34.tw1.ru/api/events/GetEvents/');
                this.events = response.data;
                console.log(response.data)
                console.log(this.items)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        // async fetchMentor() {
        //     try {
        //         const response = await axios.get('http://platforma34.tw1.ru/api/user/GetUser/')
        //     }
        // }
    // send_request() {
    //     axios.get('http://platforma34.tw1.ru/api/user/GetUsers/')
    //         .then(function (response) {
    //             // this.events = response.data
    //             console.log(response);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         })
    //         .finally(function () {
    //             // always executed
    //         })
    // }, 
    },
    mounted() {
        this.fetchData();
    },
}
</script>

<style scoped>
    html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    }
    .card-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 32px 8px;
        width: 100%;
    }
    html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    }
    .MentorImage {
        width: calc((100vw - 3.3333vw) / 3 - 0.8333vw);
        height: calc(5 / 4 * calc((100vw - 3.3333vw) / 3 - 0.8333vw));
        object-fit: cover;
        border-radius: 0.5208vw;
    }
    .MentorIntroAbout {
        grid-area: B;
        border-radius: 0.5208vw;
        background-color: #F6F6F6;
        padding: 1.5vw;
        display:flex;
        /* gap:20px; */
        flex-direction:column;
    }
    .MentorContainerDesciption {
        display:flex;
    }
    .MentorSkills {
        grid-area: C;
        background-color: #F6F6F6;
        border-radius: 0.5208vw;
        padding: 1.5vw;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
    }
    .MentorExperience {
        grid-area: D;
        background-color: #F6F6F6;
        border-radius: 0.5208vw;
        padding: 1.5vw;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
    }
    .MentorRating {
        grid-area: E;
        background-color: #F6F6F6;
        border-radius: 0.5208vw;
    }
    .MentorEducation {
        grid-area: F;
        background-color: #F6F6F6;
        border-radius: 0.5208vw;
        padding: 1.5vw;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
    }
    .MentorDescription {
        display: grid;
        gap: 0.4167vw;
        grid-template-areas:
        "B B B B B B"
        "B B B B B B"
        "C C D D E E"
        "C C F F F F";
    }
    .MentorHero {
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 2fr;
    }
    .Title {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
    }
    .ActualMero {
        display: flex;
        flex-direction: column;
    }
    .MentorRating {
        padding: 1.5vw;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
    }
    .BigInfoText {
        font-size: 2.4vw;
        font-weight: 500;
        /* height: 25px; */
        color: #454152;
        text-align: left;
    }
    .MentorIntroWords {
        color: #454152;
        font-size: 1.2vw;
        font-weight: 300;
    }
    .SmallInfoText {
        color: #454152;
        font-size: 1vw;
        font-weight: 300;
    }
</style>
