<template>
    <router-link :to="{name: 'MentorInfo', params: {id: this.id} }" style="text-decoration: none; color: inherit;">
        <slot>
        </slot>
    </router-link>
</template>

<script>
export default {

    name: 'LinkToMentor',
    props: {
        id: {
            type: String,
            required: true,
        }
    },
}

</script>
<style scoped>

</style>