<template>
    <div class="login-container">
        <form @submit.prevent="login">
            <div class="form-group">
                <label class="label">Почта</label>
                <input v-model="username" type="email" placeholder="example@gmail.com" required class="input" />
            </div>
            
            <div class="form-group">
                <label class="label">Пароль</label>
                <input v-model="password" type="password" placeholder="Введите пароль" required class="input" />
            </div>
            <button type="submit" class="submit-button">Войти</button>
        </form>
    </div> 
  <!-- <div class="login">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <div>
        <label for="username">Username:</label>
        <input type="text" v-model="username" id="username" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" v-model="password" id="password" required />
      </div>
      <button type="submit">Login</button>
    </form>
    <div v-if="errorMessage" class="error">
      {{ errorMessage }}
    </div>
  </div> -->
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('http://platforma34.tw1.ru/api/token/', {
          username: this.username,
          password: this.password
        });
        const accessToken = response.data.access;
        const refreshToken = response.data.refresh;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        console.log('User logged in:', response.data);
        this.$router.push('/dashboard');
      } catch (error) {
        this.errorMessage = 'Login failed. Please check your credentials.';
        console.error('There was an error logging in:', error.response.data);
      }
    }
  }
};
</script>

<style scoped>
    .login-container {
        display: flex;
        justify-content: center;
        min-height: 100vh;
        padding: 20px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 8vh;
    }

    .label {
        font-size: 3vh;
        font-weight: bold;
        color: #333;
        margin-bottom: 8px;
        margin-right: 30vw;
    }

    .input {
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
    }

    .submit-button {
        align-self: flex-end;
        padding: 12px 20px;
        background-color: #000;
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
    }

    .submit-button:hover {
        background-color: #333;
    }

    .form-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
</style>