<template>
    <div class="Review">
        <Spacer32/>
        <div class="ReviewContainer1">
            <div class="ReviewContainer2">
                <div class="Title">
                    Отзывы
                </div>
                <Spacer8></Spacer8>
                <div class="ReviewContainer3">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.78265 2.36469C10.0131 1.89775 10.1284 1.66428 10.2848 1.58969C10.4209 1.52479 10.5791 1.52479 10.7152 1.58969C10.8717 1.66428 10.9869 1.89775 11.2174 2.36469L13.4041 6.79466C13.4721 6.93251 13.5061 7.00144 13.5558 7.05495C13.5999 7.10233 13.6527 7.14073 13.7113 7.168C13.7776 7.1988 13.8536 7.20992 14.0057 7.23215L18.897 7.94708C19.4121 8.02237 19.6696 8.06001 19.7888 8.18581C19.8925 8.29527 19.9412 8.44567 19.9215 8.59515C19.8988 8.76696 19.7124 8.94856 19.3395 9.31176L15.8014 12.7578C15.6912 12.8652 15.636 12.9189 15.6004 12.9828C15.5689 13.0394 15.5487 13.1016 15.5409 13.1659C15.5321 13.2385 15.5451 13.3144 15.5711 13.4661L16.406 18.3335C16.494 18.8469 16.538 19.1036 16.4553 19.2559C16.3833 19.3884 16.2554 19.4814 16.1071 19.5089C15.9366 19.5404 15.7061 19.4192 15.2451 19.1768L10.8724 16.8772C10.7361 16.8056 10.668 16.7697 10.5962 16.7557C10.5327 16.7432 10.4673 16.7432 10.4038 16.7557C10.332 16.7697 10.2639 16.8056 10.1277 16.8772L5.75492 19.1768C5.29392 19.4192 5.06341 19.5404 4.89297 19.5089C4.74468 19.4814 4.61672 19.3884 4.54474 19.2559C4.462 19.1036 4.50603 18.8469 4.59407 18.3335L5.42889 13.4661C5.45491 13.3144 5.46793 13.2385 5.45912 13.1659C5.45132 13.1016 5.43111 13.0394 5.39961 12.9828C5.36402 12.9189 5.30888 12.8652 5.19859 12.7578L1.66056 9.31176C1.28766 8.94856 1.10121 8.76696 1.07853 8.59515C1.05879 8.44567 1.10755 8.29527 1.21125 8.18581C1.33044 8.06001 1.58797 8.02237 2.10304 7.94708L6.99431 7.23215C7.14642 7.20992 7.22248 7.1988 7.28872 7.168C7.34736 7.14073 7.40016 7.10233 7.44419 7.05495C7.49391 7.00144 7.52793 6.93251 7.59597 6.79466L9.78265 2.36469Z" stroke="#454152" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div class="ReviewRating">
                        4.96
                    </div>
                    <div class="ReviewButtonLink">
                        56 отзывов
                    </div>
                </div>
            </div>
            <div class="ReviewButtons">
                <a class="ReviewRightArrowButton">
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1.41113" width="31" height="31" rx="15.5" fill="white"/>
                        <rect x="1" y="1.41113" width="31" height="31" rx="15.5" stroke="#DAD9DC"/>
                        <path d="M19 21.9111L14 16.9111L19 11.9111" stroke="#454152" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a class="ReviewRightArrowButton">
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1.41113" width="31" height="31" rx="15.5" fill="white"/>
                        <rect x="1" y="1.41113" width="31" height="31" rx="15.5" stroke="#DAD9DC"/>
                        <path d="M14 21.9111L19 16.9111L14 11.9111" stroke="#454152" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
        <Spacer16></Spacer16>
        <div class="ReviewList">
            <ReviewItem></ReviewItem>
            <ReviewItem></ReviewItem>
            <ReviewItem></ReviewItem>
        </div>
    </div>
</template>
  
<script>
//   import axios from 'axios';
    // import ReviewItem from './ReviewItem.vue';
    // import ReviewList from './ReviewList.vue';
    import Spacer32 from "../Spacers/SpacerModule32.vue"
    import Spacer16 from "../Spacers/SpacerModule16.vue"
    import Spacer8 from "../Spacers/SpacerModule8.vue"
    import ReviewItem from "./ReviewItem.vue"
    export default {
    name: 'ReviewBlock',
    components: {
        ReviewItem,
        Spacer32,
        Spacer16,
        Spacer8,
    },
    // props: {
    //   review_text: {
    //     type: String,
    //     required: true
    //   },
    //   review_author: {
    //     type: String,
    //     required: true
    //   },
    //   review_event: {
    //     type: Number,
    //     required: true
    //   },
    //   event_imageUrl: {
    //     type: String,
    //     required: true
    //   },
    //   event_ageRestriction: {
    //     type: String,
    //     required: true
    //   },
    //   event_availableSpots: {
    //     type: Number,
    //     required: true
    //   },
    //   event_totalSpots: {
    //     type: Number,
    //     required: true
    //   },
    //   event_category: {
    //     type: String,
    //     required: true
    //   }
    // }
    }

</script>

<style scoped>
    html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
    }
    .ReviewList {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 32px 8px;
        width: 100%;
    }
    .ReviewContainer {

    }
    .ReviewRating {
        margin-left: 0.2vw;
        margin-right: 0.5vw;
        color: #454152;
        font-size: 1.2vw;
        font-weight: 300;
    }
    .Title {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
    }
    .ReviewButtonLink {
        border: 1px solid #454152;
        border-radius: 1vw;
        padding: 4px 8px 4px 8px;
        text-align: center;
        font-weight: 300;
    }
    .ReviewContainer1 {
        display: flex;
        justify-content: space-between;
    }
    .ReviewContainer2 {
        display: flex;
        flex-direction: column;
        /* width: 8vw; */
    }
    .ReviewContainer3 {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .SmallInfoText {
        color: #454152;
        font-size: 1vw;
        font-weight: 300;
    }
    .BigInfoText {
        font-size: 2.4vw;
        font-weight: 500;
        /* height: 25px; */
        color: #454152;
        text-align: left;
    }
</style>
  