<template>
    <div>
        <h1>Text</h1>
        <h2>New text</h2>
    </div>
    <!-- <div id="events" style="padding: 0">
        <HeaderComponent></HeaderComponent>
        <Spacer32></Spacer32>
        <BackButtonVue></BackButtonVue>
        <Spacer32></Spacer32>
        <CategoryFilter 
            :categories="categories"
            :selectedCategory="selectedCategory"
            @category-selected="filterByCategory"
            :selectCategory="(category) => {
                this.selectedCategory = category
                this.$emit('category-selected', category);
                console.log(this.selectedCategory)
            }"
        >
        <DropdownMenuVue label="Формат" :options="formats" @select="handleFormatSelect" />
        </CategoryFilter>
        
        <Spacer32></Spacer32>
        <div class="card-list">
            <EventCardVue v-for="event in events" :key="event.id"
                :title="event.title"
                :dateTime="event.dateTime"
                :price="event.price"
                :imageUrl="event.imageUrl"
                :ageRestriction="event.ageRestriction"
                :availableSpots="event.availableSpots"
                :totalSpots="event.totalSpots"
                :category="event.category"
            >
            </EventCardVue>
            
        </div>
        <div>
            
        </div>
    </div> -->
</template>

<script>
export default {
    name: 'HomePage',
}
</script>
