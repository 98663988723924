<template>
  <header class="header">
    <a href="/"> 
        <img src="../assets/images/logo/logo.svg" alt="Логотип">
    </a>
    <nav class="nav">
    <a href="/Mentor" class="nav-link">Менторы</a>
    <a href="/AllEvents" class="nav-link">Мероприятия</a>
      <!-- <router-link :to="Mentor">Mentors</router-link>
      <router-link :to="Mentor">Events</router-link>
      <router-view/> -->
    </nav>
    <a href="/login" class="login-button">Вход</a>
  </header>
</template>
  
<script>
    export default {
        name: 'HeaderComponent'
    }
</script>
  
<style scoped>

    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
  
    .header {
        margin: 0;
        padding: 0;
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 8px 8px 16px; 
        background-color: #FAF7FD;
        border-radius: 32px;
    }
  
    .nav {
        display: flex;
        gap: 28px;
        padding: 0px 30px 0px 30px ;
    }
    
    .nav-link {
        text-decoration: none;
        color: gray;
        font-size: 16px;
        padding: 4px 8px 4px 8px;
        border-radius: 32px;
    }
    
    .nav-link:hover {
        background-color: #dcd8fc;
        cursor: pointer;
    }
    

    .router-link {
        text-decoration: none;
        color: gray;
        font-size: 16px;
    }
    
    .login-button {
        background-color: #6a41f2;
        max-height: 44px;
        max-width: 94px;
        color: white;
        border: none;
        padding: 12px 24px 12px 24px;
        border-radius: 32px;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
    }

    .login-button:hover {
        background-color: #322965;
        color: white;
        cursor: pointer;
    }
</style>
  