<template>
    <div class="register-container"> 
        <form @submit.prevent="register">

            <div class="form-group">
                <label class="label-register">Как вас зовут?</label>
                <div class="name-fields">
                    <input v-model="formData.lastName" type="text" placeholder="Фамилия" required class="input"/>
                    <input v-model="formData.firstName" type="text" placeholder="Имя" required class="input"/>
                </div>
            </div>
            
            <div class="form-group">
                <label class="label-register">Возраст</label>
                <input v-model="formData.age" type="number" placeholder="25" required class="input"/>
            </div>
            
            <div class="form-group">
                <label class="label-register">Город проживания</label>
                <input v-model="formData.city" type="text" placeholder="Москва" required class="input"/>
            </div>
            
            <div class="form-group">
                <label class="label-register">Почта</label>
                <input v-model="formData.email" type="email" placeholder="example@gmail.com" required class="input"/>
            </div>
            
            <button type="submit">Завершить регистрацию</button>
        </form>
    </div> 
<!-- <div class="register">
    <h2>Register</h2>
    <form @submit.prevent="register">
      <div>
        <label for="username">Username:</label>
        <input type="text" v-model="username" id="username" required />
      </div>
      <div>
        <label for="email">Email:</label>
        <input type="email" v-model="email" id="email" required />
      </div>
      <button type="submit">Register</button>
    </form>
    <div v-if="errorMessage" class="error">
      {{ errorMessage }}
    </div>
  </div> -->
</template>

<script>       
import axios from 'axios';

export default {
    data() {
        return {
        formData: {
            lastName: '',
            firstName: '',
            age: '',
            city: '',
            email: ''
        }
        };
    },
//   data() {
//     return {
//       username: '',
//       email: '',
//       password: '',
//       errorMessage: ''
//     };
//   },
  methods: {
    async register() {
      try {
        const response = await axios.post('http://platforma34.tw1.ru/api/user/AddUser/', {
          user_name: this.username,
          user_email: this.email,
        });
        console.log('User registered:', response.data);
        this.$router.push('/login');
      } catch (error) {
        this.errorMessage = 'Registration failed. Please try again.';
        console.error('There was an error registering:', error.response.data);
      }
    }
  }
};
</script>

<style>
    .register-container {
        display: flex;
        min-height: 100vh;
        padding: 20px;
        justify-content: center;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 8vh;
    }

    .label {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        margin-bottom: 8px;
        margin-right: 30vw;
    }

    .input {
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
    }

    .name-fields {
        display: flex;
        flex-direction: row;
        /* justify-content: space-between; */
        gap: 20px;
    }

    .name-input {
    width: 50%;
    }

    .age-input {
        width: 80px;
    }

    .submit-button {
        align-self: flex-end;
        padding: 12px 20px;
        background-color: #000;
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
    }

    .submit-button:hover {
        background-color: #333;
    }

    .form-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .label-register {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        margin-bottom: 8px;
        margin-right: 30vw;
    }
</style>