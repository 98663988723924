<template>
    <div>
        <h1>Text</h1>
        <h2>New text</h2>
    </div>

    <!-- <iframe
        src="http://salutejazz.ru/656ddd?psw=OB4VVUdXUBEfDhJDGENHAhAEDw" 
        height="1000px"
        width="1000px"
    ></iframe> -->

    <button @click="getConferenceAudioDevices">Получить список людей</button>
    <!-- <div>
        <h3>Количество людей в конференции: {{ audioOutputDevices.length }}</h3>
        <ul>
            <li v-for="participant in audioOutputDevices" :key="participant.id">
                {{ participant.name }} ({{ participant.role }})
            </li>
        </ul>
    </div> -->

</template>

<!-- <script setup lang="ts"> -->
<script>
import { createJazzWebSdk, createJazzClient, createSdkToken, JazzSdk, JazzClient, getLocalDevices } from '@salutejs/jazz-sdk-web';
import {
    audioOutputMixerPlugin,
    // logsPlugin,
    // videoElementPoolPlugin,
} from '@salutejs/jazz-sdk-web-plugins';
export default {
    name: 'TestPage',
    data() {
        return {
            authStatus: 'Not Authenticated',
            sdk: {
                type: JazzSdk,
            },
            jazzClient: {
                type: JazzClient,
            },
            conference: null,
            participants: [],
            // localDevices: [],
            // audioOutputDevices: [],
        };
    },
    async mounted() {
        try {
            console.log('SDK code started');
            // Инициализация SDK
            const sdk = await createJazzWebSdk(
                {
                    plugins: [
                        // videoElementPoolPlugin(),
                        audioOutputMixerPlugin(),
                        // logsPlugin({
                        // logLevel: 'debug',
                        // }),
                    ],
                }
            );
            console.log('SDK jazz created');
            console.log(typeof (sdk));
            // Создание клиента
            const jazzClient = await createJazzClient(sdk, {
                serverUrl: 'https://salutejazz.ru', // правильный формат URL
            });
            console.log('SDK jazz client');
            console.log(typeof (jazzClient));
            // Создание SDK токена для авторизации
            const sdkToken = await createSdkToken('eyJwcm9qZWN0SWQiOiIxYzBmZjI1Mi01YTEzLTQ4OTItYjdiMy04ZDVjNmIxNjlhNzQiLCJrZXkiOnsia3R5IjoiRUMiLCJkIjoiSGg2Tm1GMHV5aEFzOElHbVdWNi1ZV3l1QmRkTmVhTE1GU0ZYYzN6eHFZRmFuZU15N0E2UTNVSEktNVRLbnlSVyIsInVzZSI6ImVuYyIsImNydiI6IlAtMzg0Iiwia2lkIjoiZmMyZjBmYTAtZTdjOS00ODhmLTk4YzMtYjZkYWU5YTZkYzQ5IiwieCI6InhFbUU1UVRCU3pSZ2k4anQzN2JiaHZfTnh1RHJiQi01QjlpMWpnYm1Fei1NbUFaWllPTmNDakxWMFI5VHJrSloiLCJ5IjoiYWV1ZzFRbm9rX0hnVTAtZUxrWXF0a0ozUU1ROUR3NXVmRjBnR2NraXNhMEZETmc2ODFtSzZWaWtfS2REOWk4eCJ9fQ==', {
                iss: 'PlatformaApp',
                userName: 'DPKlitr',
            });
            console.log('SDK token is created');
            console.log(typeof (sdkToken));
            console.log(sdkToken);
            // Аутентификация клиента с использованием SDK токена
            await jazzClient.auth.loginBySdkToken(sdkToken.sdkToken);
            console.log('SDK и клиент успешно инициализированы');
            this.conference = await jazzClient.conferences.createConference({
                title: 'Homospermicall',
                guestEnabled: true,
            });
            console.log(this.conference);
            console.log('conference url is', this.conference.url);
            console.log('conference id is', this.conference.id);
            this.sdk = sdk
            // console.log('sdk id is', sdk);
            // const localDevices = getLocalDevices(sdk);

            // const { audio, video } = await localDevices.requestUserMediaPermissions(
            //     'audio',
            //     'video',
            // );

            // const audioInputDevices = localDevices.audioInputDevices.get();
            // const audioOutputDevices = localDevices.audioOutputDevices.get();
            // const videoInputDevices = localDevices.audioOutputDevices.get();
            // console.log("audio in is ",audioInputDevices)
            // console.log("audio out is ",audioOutputDevices)
            // console.log("video is ",videoInputDevices)
        } catch (error) {
            console.error('Ошибка при инициализации:', error);
        }

    },
    methods: {
        // async getConferenceParticipants() {
        //     try {
        //         console.log('conference id is in getConferenceParticipants', this.conference.id);
        //         if (this.conference) {
        //             const participants = await this.jazzClient.conferences.getParticipants(this.conference.id);
        //             this.participants = participants;
        //             console.log('Участники конференции:', participants);
        //         } else {
        //             console.log('Участников конференции нет');
        //         }
        //     } catch (error) {
        //         console.error('Ошибка при получении списка участников:', error);
        //     }
        // }
        async getConferenceAudioDevices() {
            try {
                const localDevices = getLocalDevices(this.sdk);
                // console.log('sdk is in getConferenceAudioDevices');
                // console.log(this.sdk);
                // const localDevices = getLocalDevices("23453142542rfwe324");
                console.log('local devices in getConferenceAudioDevices');
                console.log(localDevices);
                const audioOutputDevices = localDevices.audioOutputDevices.get();
                const audioOutput = localDevices.audioOutput.get();
                console.log('conference id is in getConferenceAudioDevices', this.conference.id);
                console.log('audioOutputDevices Устройства вывода:');
                console.log(audioOutputDevices);
                console.log('audioOutput Устройства вывода:', audioOutput);

                const selectedAudioInputMediaStream = await localDevices.getSelectedAudioInputStream();
                console.log('it is selected audio', selectedAudioInputMediaStream)
                const selectedVideoInputMediaStream = await localDevices.getSelectedVideoInputStream();
                console.log('it is selected video', selectedVideoInputMediaStream)
                // if (this.conference) {
                //     this.audioOutputDevices = await this.localDevices.audioOutputDevices.get();
                //     console.log('Устройства вывода:', this.audioOutputDevices);
                // } else {
                //     console.log('Устройств вывода нет');
                // }
            } catch (error) {
                console.error('Ошибка при получении списка устройств:', error);
            }
        }

    },
}
</script>
